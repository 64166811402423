:root {
  --configuration-color: #ffffff;
  --border-color: #cecece;
  --header-color: #fff;
  --chart-bg-color: #ffffff;
  --chart-grid-line-color: #cecece;
  --chart-line-color: #cecece;
  --chart-legend-color: #4f4f4f;
  --chart-axias-text-color: #8a8a8a;
  --chart-title-color: #4f4f4f;
  --collapsed-icon-color: #1b1f3b;
  --main-bg-color: #f4f4f4;
  --header-bg-color: #ffffff;
  --theme-color: #4f4f4f;
  --primary-color: #f9be00;
  --secretary-color: #8a8a8a;
  --second-chart-bg-color: #fbfbfb;
  --home-img-one: url(/static/svg/Capacity_Installed.svg);
  --battery-img: url(/static/svg/air-conditioner-light.svg);
  --home-img-two: url(/static/svg/Power_Installed.svg);
  --home-img-three: url(/static/svg/PV_Generation.svg);
  --home-img-four: url(/static/svg/Today_Charge.svg);
  --home-img-five: url(/static/svg/TOTAL_DISCHARGED.svg);
  --text-opacity-color: rgba(51, 51, 51, 0.8);
  --bg-opacity-color: rgba(255, 255, 255, 0.75);
  --text-opacity-color-80: rgba(255, 255, 255, 0.8);
  --map-land: #f5f5f3;
  --map-water: #ccd2d3ff;
  --home-text-color: rgba(51, 51, 51, 0.8);
  --home-chart-bg-color: rgba(251, 251, 251, 0.8);
  --home-chart-circle-bg-color: #e2e2e2;
  --home-chart-circle-title-text-color: rgba(0, 0, 0, 0.45);
  --home-chart-circle-title-text-color-total: rgba(0, 0, 0, 0.8);
  --home-more-bg-color: rgba(251, 251, 251, 0.8);
  --home-more-titlt-color: #4f4f4f;
  --home-more-btn-text-color: #4f4f4f;
  --home-more-btn-bg-color: #ffffff;
  --home-more-btn-border-color: #989494;
  --home-data-text-color: rgba(0, 0, 0, 0.8);
  --home-baidu-map-bg-color: #ffffff;
  --home-baidu-map-text-color: rgba(51, 51, 51, 0.8);
  --home-profit-text-color: #8a8a8a;
  --home-data-text-color-two: #8a8a8a;
  --baidu-map-w3: url(/static/images/w3.png);
  --tooltip-bg-color: #3e3f43;
  --tooltip-color-text-one: rgba(51, 51, 51, 0.8);
  --exporting-bg-color: #ffffff;
  --cluster-data-header-bg-color: #f7f8fa;
  --chart-axis-text-color: #989494;
  --bread-high-light-color: rgba(51, 51, 51, 0.9);
  --bread-text-color: rgba(51, 51, 51, 0.8);
  --electrical-flow-chart-flow: "/resource/mxgraph/shapes/12/11.png";
  --electrical-flow-chart-tank: "/resource/mxgraph/shapes/12/10.png";
  --electrical-flow-chart-pv: "/resource/mxgraph/shapes/12/9.png";
  --electrical-flow-chart-otherLoad: "/resource/mxgraph/shapes/12/7.png";
  --electrical-flow-chart-importantLoad: "/resource/mxgraph/shapes/12/6.png";
  --electrical-flow-chart-fuse: "/resource/mxgraph/shapes/12/5.png";
  --electrical-flow-chart-electricityMeter: "/resource/mxgraph/shapes/12/4.png";
  --electrical-flow-chart-container: "/resource/mxgraph/shapes/12/3.png";
  --electrical-flow-chart-powerGrid: "/resource/mxgraph/shapes/12/2.png";
  --electrical-flow-chart-chargingPile: "/resource/mxgraph/shapes/12/1.png";
  --electrical-flow-chart-battery: "/resource/mxgraph/shapes/12/0.png";
  --mxgraph-bg-img: url(/resource/mxgraph/images/mxgraph-content-light.svg);
  --mxgraph-select-border-color: #cccc;
  --mxgraph-input-bg-color: #ffffff;
  --mxgraph-option-bg-color: #ffffff;
  --mxgraph-option-text-color: #ffffff;
  --mxgraph-option-hover-color: #ffffff;
  --mxgraph-select-text-color: #ffffff;
  --mxgraph-split-hover-color: #e0e0e0;
  --mxgraph-dialog-bg-color: rgba(0, 0, 0, 0.15);
  --select-border-color: #cecece;
  --mxgraph-grid-color: #d0d0d0;
  --card-grid-bg: #cecece;
  --fontSize_45: 0.6rem;
}

@titlebg :rgb (56, 98, 148);:export {
  menuText: #b5b1b1;
  menuActiveText: #fff;
  subMenuActiveText: #fff;
  menuBg: #464d55;
  menuHover: #2f3c44;
  subMenuBg: #464d55;
  subMenuHover: #2f3c44;
  sideBarWidth: 4rem;
  containerBg: #323b40;
  borderColor: #323b40;
  breadcrumbBg: #0c4555;
  lighBlue: #03e4eb;
  deepYellow: #f8b90a;
  runStatusNormal: #87ca16;
  runStatusWarning: #ff9500;
  runStatusFault: #ff4a55;
  runStatusOffline: #999;
  menuActiveBg: #fff;
  cardMarginTop: 0.21rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-0.4rem);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(0.4rem);
}

.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all .5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(0.27rem);
}

.breadcrumb-move {
  transition: all .5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.customer-dialog .el-button {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.customer-dialog .el-button:hover {
  background-color: #286090;
  border-color: #204d74;
}

.customer a:hover {
  color: #87ca16;
}

.customer a.has-file-download {
  max-width: 1.33rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: block;
}

.dialog a {
  color: #337ab7;
}

.dialog a:hover {
  color: #23527c;
}

.create_new {
  display: inline-block;
  padding: 0.05rem 0.11rem;
  border-radius: 0.05rem;
  background-color: #b8b8b8;
  color: #fff;
}

.processing {
  display: inline-block;
  padding: 0.05rem 0.11rem;
  border-radius: 0.05rem;
  background-color: #337ab7;
  color: #fff;
}

.finish {
  display: inline-block;
  padding: 0.05rem 0.11rem;
  border-radius: 0.05rem;
  background-color: #5cb85c;
  color: #fff;
}

.comment {
  display: inline-block;
  padding: 0.05rem 0.11rem;
  border-radius: 0.05rem;
  background-color: #5cb85c;
  color: #fff;
}

.blue-btn {
  background: #324157;
}

.blue-btn:hover {
  color: #324157;
}

.blue-btn:hover:before,
.blue-btn:hover:after {
  background: #324157;
}

.light-blue-btn {
  background: #3a71a8;
}

.light-blue-btn:hover {
  color: #3a71a8;
}

.light-blue-btn:hover:before,
.light-blue-btn:hover:after {
  background: #3a71a8;
}

.red-btn {
  background: #c03639;
}

.red-btn:hover {
  color: #c03639;
}

.red-btn:hover:before,
.red-btn:hover:after {
  background: #c03639;
}

.pink-btn {
  background: #e65d6e;
}

.pink-btn:hover {
  color: #e65d6e;
}

.pink-btn:hover:before,
.pink-btn:hover:after {
  background: #e65d6e;
}

.green-btn {
  background: #30b08f;
}

.green-btn:hover {
  color: #30b08f;
}

.green-btn:hover:before,
.green-btn:hover:after {
  background: #30b08f;
}

.tiffany-btn {
  background: #4ab7bd;
}

.tiffany-btn:hover {
  color: #4ab7bd;
}

.tiffany-btn:hover:before,
.tiffany-btn:hover:after {
  background: #4ab7bd;
}

.yellow-btn {
  background: #fec171;
}

.yellow-btn:hover {
  color: #fec171;
}

.yellow-btn:hover:before,
.yellow-btn:hover:after {
  background: #fec171;
}

.txtBtnYellowBtn span {
  color: #fec171;
}

.custom-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  color: #fff;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0.13rem 0.2rem;
  font-size: 0.19rem;
  border-radius: 0.05rem;
}

.main-table {
  font-size: 0.16rem;
}

.main-table table thead .cell {
  height: 100%;
  color: #313131;
  font-size: 0.16rem;
  line-height: 0.21rem;
}

.main-table table tbody .cell {
  height: 100%;
  color: #313131;
  font-size: 0.16rem;
  line-height: 0.21rem;
}

.home-position-list::-webkit-scrollbar {
  width: 0.07rem;
  height: 0.13rem;
  background-color: #323b40;
}

.home-position-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  border-radius: 0.05rem;
  background-color: #323b40;
}

.home-position-list::-webkit-scrollbar-thumb {
  height: 0.05rem;
  border-radius: 0.05rem;
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -webkit-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -moz-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -ms-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  background-color: #323b40;
}

.scrollbox_ant-layout::-webkit-scrollbar {
  width: 0.07rem;
  height: 0.13rem;
  background-color: #323b40;
}

.scrollbox_ant-layout::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  border-radius: 0.05rem;
  background-color: #323b40;
}

.scrollbox_ant-layout::-webkit-scrollbar-thumb {
  height: 0.05rem;
  border-radius: 0.05rem;
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -webkit-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -moz-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -ms-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  background-color: #323b40;
}

.dark-white-scroll .ant-table-content::-webkit-scrollbar {
  width: 0.05rem;
  height: 0.05rem;
  background-color: #cecece;
}

.dark-white-scroll .ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  border-radius: 0.03rem;
  background-color: #f4f4f4;
}

.dark-white-scroll.auto-scroll::-webkit-scrollbar {
  width: 0.06rem;
  height: 0.06rem;
  background-color: #cecece;
}

.dark-white-scroll.auto-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  border-radius: 0.03rem;
  background-color: #f4f4f4;
}

.search-list .el-date-editor.el-input {
  width: 40%;
}

.no-data {
  color: #4f4f4f;
  color: var(--theme-color);
}

.no-data.p-l-22 {
  padding-left: 0.29rem;
}

.customer-table .ant-table {
  background-color: #323b40;
}

.customer-table .ant-table td {
  color: #f8b90a;
}

.customer-table .ant-table tr,
.customer-table .ant-table th {
  background-color: #323b40;
}

.customer-table .ant-table .ant-table-tbody td {
  background-color: #323b40;
}

.customer-table .ant-table .ant-table-thead>tr>th,
.customer-table .ant-table .ant-table-tbody>tr>td {
  border-bottom: 0.01rem solid #323b40;
}

.customer-table .ant-table .ant-table-thead>tr>th {
  color: #ccc;
  background-color: #323b40;
}

.customer-table .ant-table .ant-table-tbody tr:hover>td {
  background-color: #fff;
  border-bottom: 0;
}

.customer-table .ant-table:before {
  background-color: #323b40;
}

body .ant-select-multiple .ant-select-selection-item-remove {
  color: #4f4f4f;
}

body .bms-battery-package-data .chuan-battery {
  width: 0.6rem;
  margin-right: 0.04rem;
}

body .bms-battery-package-data .chuan-battery span {
  display: inline-block;
  padding: 0.03rem 0.03rem;
}

body .ant-pagination-options-quick-jumper input {
  border: 0.01rem solid #cecece;
}

body .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
body .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #cecece;
}

body table.table-space {
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

body table.table-space td {
  padding: 0.03rem 0.07rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body #root .bms-bat-package-data-title {
  display: inline-block;
  padding-bottom: 0.07rem;
  border-bottom: 0.01rem solid #cecece;
  margin-bottom: 0.4rem;
}

body #light-en {
  margin-left: 0.03rem;
}

body .facion_ZH {
  width: 2.67rem;
  margin-bottom: -0.25rem;
  margin-left: 0.03rem;
}

body .facion_EN {
  width: 3.17rem;
  margin-bottom: -0.25rem;
  margin-left: -0.08rem;
}

body .side-menu-zh {
  font-size: 0.21rem;
}

body .side-menu-zh .ant-menu-item .anticon {
  font-size: 0.21rem;
}

body .side-menu-en {
  font-size: 0.23rem;
}

body .side-menu-en .ant-menu-item .anticon {
  font-size: 0.23rem;
}

body .ant-menu-item-active .ant-menu-title-content span {
  color: #e8aa0d !important;
  font-weight: bold;
}

body .ant-menu-submenu-active .ant-menu-submenu-title .ant-menu-title-content span {
  color: #e8aa0d !important;
  font-weight: bold;
}

body .ant-menu-submenu-selected div {
  background-color: #4f4f4f;
}

body .sider-menu-dark .ant-menu-item-selected a,
body .sider-menu-dark .ant-menu-item-selected a:hover {
  color: #e8aa0d;
}

body .sider-menu .ant-menu-item-selected {
  background-color: #f5f5f5 !important;
}

body .sider-menu .ant-menu-item-selected a,
body .sider-menu .ant-menu-item-selected a:hover {
  color: #e8aa0d;
  font-weight: bold;
}

body .ant-menu-inline,
body .ant-menu-vertical,
body .ant-menu-vertical-left {
  border-width: 0;
}

body .ant-layout-sider::-webkit-scrollbar {
  width: 0.05rem;
  height: 0.07rem;
  background-color: #e1e1e1;
}

body .ant-layout-sider::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.05rem rgba(0,0,0,.1);
  border-radius: 0.05rem;
  background-color: #989494;
}

body .ant-layout-sider::-webkit-scrollbar-thumb {
  height: 0.07rem;
  border-radius: 0.07rem;
  -webkit-box-shadow: inset 0 0 0.05rem rgba(0,0,0,.1);
  background-color: #e1e1e1;
}

body .ant-layout {
  background: #f4f4f4;
  background: var(--main-bg-color);
}

body .ant-layout-sider {
  border-bottom-right-radius: 0.13rem;
  background: #fff;
}

body .ant-layout-sider .ant-layout-sider-children {
  margin-right: 0.13rem;
  overflow: hidden;
}

body .ant-layout-sider .ant-layout-sider-children>.ant-menu {
  height: calc(100vh - 2.13rem);
  overflow-y: auto;
  overflow-x: hidden;
}

body .ant-layout-sider .ant-layout-sider-children .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-image: url(/static/svg/border-menu.svg);
  background-size: 0.08rem 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.flow1 {
  animation-name: move-lines;
  animation-duration: 2000ms;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-iteration-count: infinite;
  stroke-dasharray: 5,5;
}

.flow2 {
  animation-name: move-lines2;
  animation-duration: 2000ms;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-iteration-count: infinite;
  stroke-dasharray: 5,5;
}

@keyframes move-lines {
  from {
    stroke-dashoffset: 30;
  }

  to {
    stroke-dashoffset: -30;
  }
}

@keyframes move-lines2 {
  from {
    stroke-dashoffset: -30;
  }

  to {
    stroke-dashoffset: 30;
  }
}

#root .ant-layout {
  overflow: auto !important;
}

body {
  height: 100%;
  line-height: 1.15 !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: WenQuanYi Micro Hei,Arial,sans-serif;
}

body span.table-ellipsis {
  display: inline-block;
  max-width: 0.67rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

body .switch-cus.ant-switch {
  background-image: hsla(0,0%,100%,.4);
}

body .switch-cus .ant-switch-handle::before {
  background-color: #fff;
}

body .mt-20 {
  margin-top: 0.27rem;
}

body .text-center {
  text-align: center;
}

body .flex-1 {
  flex: 1 1 !important;
}

body .flex-2 {
  flex: 2 1 !important;
}

body .flex-3 {
  flex: 3 1 !important;
}

body .flex-4 {
  flex: 4 1 !important;
}

body .flex-5 {
  flex: 5 1 !important;
}

body .flex-6 {
  flex: 6 1 !important;
}

body .flex-7 {
  flex: 7 1 !important;
}

body .flex-8 {
  flex: 8 1 !important;
}

body .flex-9 {
  flex: 9 1 !important;
}

body .flex-10 {
  flex: 10 1 !important;
}

body .width-percent {
  width: 100% !important;
}

body .width-percent-70 {
  width: 70% !important;
}

body .align-items {
  align-items: center;
}

body .hidden {
  display: none;
}

body .white-word {
  color: #fff;
}

body .normal {
  color: #06c584;
}

body .warning {
  color: #ea8041;
}

body .fault {
  color: #ff6257;
}

body .update {
  color: #06c584;
}

body .static {
  color: #06c584;
}

body .ant-descriptions ul.sub-detail {
  display: inline-block;
  padding-right: 0.11rem;
}

body .ant-descriptions ul.sub-detail li {
  padding: 0.13rem 0;
  width: 100%;
  border-bottom: 0.01rem solid #5a5a5a;
}

body .ant-descriptions ul.sub-detail li label {
  padding-right: 0.2rem;
  color: #d4d4d4;
}

body .ant-descriptions ul.sub-detail-more li {
  padding: 0.13rem 0;
  display: inline-block;
}

body .ant-descriptions ul.sub-detail-more li label {
  padding-right: 0.2rem;
  color: #d4d4d4;
}

body .ant-descriptions ul.sub-detail-more li span {
  display: block;
  text-align: center;
}

body .ant-descriptions .ant-descriptions-title {
  color: #fff;
}

body .ant-descriptions .ant-descriptions-item-label {
  color: #d4d4d4;
}

body .ant-descriptions .ant-descriptions-item-content {
  color: #fff;
}

body .ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #2e2f34;
  border-color: #5a5a5a;
}

body .ant-descriptions.ant-descriptions-bordered .ant-descriptions-item-content {
  border-color: #5a5a5a;
}

body .ant-descriptions .ant-descriptions-row,
body .ant-descriptions .ant-descriptions-view {
  border-color: #5a5a5a;
}

body .ant-table-tbody>tr.ant-table-row-selected>td {
  background-color: #4f4f4f !important;
}

body .comfirm-delte-color {
  color: #000;
}

body .ant-select-dropdown {
  color: #989494;
}

body .ant-cascader-menu-item:hover {
  background: #27282e;
}

body .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
body .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: #27282e;
}

body .ant-picker {
  border-radius: 0.07rem !important;
}

body .uploadImage {
  font-weight: 400;
  color: #333 !important;
}

body .channelList-status {
  display: flex;
  justify-content: left;
  align-items: center;
}

body .channelList-status>span {
  margin-left: 0.2rem;
  color: #4f4f4f;
}

body .system-status {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

body .system-status>span {
  margin-right: 0.13rem;
  color: #4f4f4f;
}

body .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: #4f4f4f;
  border-radius: 0;
}

body .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  color: #f9be00;
  background: #4f4f4f;
}

body .ant-input-number-group-addon {
  color: #4f4f4f;
}

body .ant-pagination-item-link {
  color: #000;
}

body .ant-pagination-disabled .ant-pagination-item-link {
  color: "#989494";
}

body .ant-pagination-options-quick-jumper {
  color: #000;
}

body .ant-pagination-options-quick-jumper>input {
  color: #000;
}

body .ant-pagination.mini .ant-pagination-total-text {
  color: #000;
}

body .ant-pagination.mini .ant-pagination-item-active {
  background: #fabf00;
}

body .ant-pagination.mini .ant-pagination-item-active>a {
  color: #fff;
}

body .doubleItemColor .ant-input-number-group-addon {
  color: #989494;
  background-color: #4f4f4f !important;
  border-radius: 0.03rem;
}

body .border-color-gc_mode {
  border: 0.01rem solid #000;
}

body .ant-card-grid {
  float: left;
  box-shadow: 0.01rem 0 0 0 #e0e0e0,0 0.01rem 0 0 #e0e0e0,0.01rem 0.01rem 0 0 #e0e0e0,0.01rem 0 0 0 #e0e0e0 inset,0 0.01rem 0 0 #e0e0e0 inset;
  box-shadow: 0.01rem 0 0 0 var(--mxgraph-split-hover-color),0 0.01rem 0 0 var(--mxgraph-split-hover-color),0.01rem 0.01rem 0 0 var(--mxgraph-split-hover-color),0.01rem 0 0 0 var(--mxgraph-split-hover-color) inset,0 0.01rem 0 0 var(--mxgraph-split-hover-color) inset;
}

body .title {
  color: #4f4f4f;
}

body .ant-empty-description {
  color: #4f4f4f;
}

body input:-webkit-autofill {
  box-shadow: 0 0 0 13.33rem #f4f4f4 inset !important;
  -webkit-text-fill-color: #4f4f4f !important;
  -webkit-background-clip: text;
}

body .max-min-range {
  color: #4f4f4f;
}

body .ant-input-number-disabled .ant-input-number-input {
  background-color: #e1e1e1;
}

body .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #e1e1e1;
}

body .ant-select-selection-item {
  color: #000;
}

body .ant-pagination-item>a {
  color: #000;
}

body .clear-both {
  clear: both;
}

body .btn-radius_25.ant-btn {
  border-radius: 0.33rem;
}

body .pickter-radius_25.ant-picker {
  border-radius: 0.33rem;
  border-color: #4f4f4f;
}

body .pickter-radius_25.ant-picker .ant-picker-input>input {
  color: #4f4f4f;
}

body .pickter-radius_25.ant-picker .ant-picker-suffix {
  color: #4f4f4f;
}

body .ant-form.form-mg-0 .ant-form-item {
  margin-bottom: 0.04rem;
}

body .form-item-mg-0.ant-form-item {
  margin-bottom: 0.04rem;
}

body .antd-input-no-bg.ant-input,
body .antd-input-no-bg .ant-input {
  background: rgba(0,0,0,0);
}

body .btn_white.ant-btn span {
  color: #fff;
}

body .chart-exporting-buttons image {
  width: 1rem !important;
  height: 0.4rem;
  cursor: pointer;
}

body .highcharts-contextmenu ul.highcharts-menu {
  border-width: 0 !important;
  border-radius: 0.07rem;
  background: #fff !important;
  box-shadow: 0.03rem 0.03rem 0.16rem rgba(0,0,0,.25) !important;
}

body .highcharts-contextmenu ul.highcharts-menu li.highcharts-menu-item {
  color: #8a8a8a !important;
}

body .highcharts-contextmenu ul.highcharts-menu li.highcharts-menu-item:hover {
  background: #f7f8fa !important;
  color: rgba(51,51,51,.8) !important;
  font-weight: bolder !important;
}

body .radio-group-self>label {
  background: rgba(0,0,0,0);
  color: #989494;
  border-color: rgba(0,0,0,0);
  height: 0.6rem;
  line-height: 0.53rem;
}

body .radio-group-self>label.ant-radio-button-wrapper:first-child {
  border-left: 0.02rem solid rgba(0,0,0,0);
}

body .radio-group-self>label.ant-radio-button-wrapper:not(:first-child):before {
  background: none;
}

body .radio-group-self>label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: rgba(0,0,0,0);
  border-bottom: 0.04rem solid #f9be00 !important;
  color: #4f4f4f;
}

body .radio-group-self>label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: rgba(0,0,0,0);
}

body .radio-group-self>label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: rgba(0,0,0,0);
}

body .radio-group-self>label.ant-radio-button-wrapper-checked:not([class*="   ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: rgba(0,0,0,0);
}

body .radio-group-self>label span:last-child {
  font-size: 0.19rem;
}

body .radio-group-self>label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0;
}

body .radio-group-table label {
  overflow: hidden;
  background: rgba(0,0,0,0);
  border-bottom: 0.01rem #4f4f4f solid;
  border-top: 0.01rem #4f4f4f solid;
  border-right: 0.01rem #4f4f4f solid;
  color: #4f4f4f;
}

body .radio-group-table label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0;
}

body .radio-group-table label.ant-radio-button-wrapper:first-child {
  border-left: 0.01rem solid #4f4f4f;
}

body .radio-group-table label.ant-radio-button-wrapper-checked {
  border-color: #ffd429 !important;
}

body .border-bottom-white-style {
  border-bottom: 0.01rem solid #989494;
}

body .border-bottom-grey-style {
  border-bottom: 0.01rem solid #4f4f4f;
}

body .has-file-upload {
  position: relative;
  color: #ccc;
}

body .has-file-upload .vir-btn {
  position: absolute;
  top: 0.05rem;
  height: 0.4rem;
  line-height: 0.4rem;
  min-width: 1.07rem;
  z-index: 3;
  text-align: center;
  border: 0.01rem solid #cecece;
  border: 0.01rem solid var(--border-color);
  border-radius: 0.05rem;
  padding: 0 0.05rem;
}

body .has-file-upload input {
  position: absolute;
  left: 0;
  top: 0.05rem;
  height: 0.37rem;
  line-height: 0.37rem;
  width: 1.07rem;
  z-index: 10;
  opacity: 0;
}

body .has-file-upload span {
  display: inline-block;
  margin-left: 1.33rem;
}

body #cancel:hover {
  color: #87ca16;
}

body .customer-modal-style .ant-modal .anticon.anticon-close {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

body .customer-modal-style .ant-input {
  width: 95%;
}

body .customer-modal-style .ant-tree .ant-tree-switcher {
  display: -moz-flex;
  display: flex;
  align-items: center;
}

body #normal_login .ant-form-item-label label {
  color: #ccc;
  font-size: .15rem;
}

body #normal_login .ant-input-number-input {
  background-color: #323b40;
  color: #fff;
  border-color: #b5b1b0;
  font-size: .15rem;
}

body .container-height .ant-spin-container {
  height: auto !important;
  min-height: 1.33rem !important;
  height: 1.33rem;
}

body .register_mainBox .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: .36rem;
}

body .register_mainBox .ant-select {
  height: .36rem;
}

body .register_mainBox .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  line-height: .3rem;
}

body .curtomer-style .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(0,0,0,0) !important;
  color: #ccc;
  border-color: #666;
  font-size: .15rem;
}

body .curtomer-style .ant-picker,
body .curtomer-style .ant-select,
body .curtomer-style .ant-input-affix-wrapper {
  background-color: rgba(0,0,0,0) !important;
  color: #fff;
  font-size: .15rem !important;
  border: .01rem solid #b5b1b0;
}

body .curtomer-style .ant-radio-button-wrapper:first-child:last-child {
  border-radius: 0;
}

body .curtomer-style .ant-input,
body .curtomer-style .ant-radio-button-wrapper,
body .curtomer-style .ant-input-number {
  background-color: rgba(0,0,0,0);
  color: #fff;
  font-size: .15rem !important;
  width: 100%;
}

body .curtomer-style .ant-picker-suffix {
  color: #666;
}

body .curtomer-style .ant-picker-input>input {
  color: #ccc;
  font-size: .15rem;
}

body .curtomer-style .ant-pagination {
  margin-top: 0.27rem;
  float: right;
  font-size: .15rem;
}

body .curtomer-style .ant-pagination .ant-pagination-options-quick-jumper {
  color: #ccc;
}

body .curtomer-style .ant-pagination .ant-pagination-options-quick-jumper input {
  background: rgba(0,0,0,0);
  color: #ccc;
}

body .curtomer-style .ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
body .curtomer-style .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #333;
}

body .curtomer-style .ant-pagination .ant-pagination-item,
body .curtomer-style .ant-pagination .ant-pagination-prev .ant-pagination-item-link,
body .curtomer-style .ant-pagination .ant-pagination-next .ant-pagination-item-link {
  background-color: #3d4957;
  border: #fff;
  color: #fff;
}

body .curtomer-style .ant-pagination .ant-pagination-jump-prev {
  color: #fff !important;
}

body .curtomer-style .ant-pagination .ant-pagination-item a {
  color: #fff;
}

body .curtomer-style .ant-pagination .ant-pagination-item:hover {
  background-color: #f9be00;
}

body .curtomer-style .ant-pagination .ant-pagination-item-active {
  background-color: #f9be00;
}

body .curtomer-style .ant-pagination .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #fff !important;
}

body .curtomer-style .ant-radio-disabled+span {
  color: #ccc;
}

body .curtomer-style .ant-table {
  background-color: rgba(0,0,0,0);
}

body .curtomer-style .ant-table .ant-table-thead tr>th {
  color: #b5b1b0 !important;
  background: #323b40;
  font-size: .15rem;
  border-color: #edb320;
  color: #333;
}

body .curtomer-style .ant-table .ant-table-tbody tr>td {
  background: #323b40;
  font-size: .15rem;
  border-color: #62666d;
  color: #b5b1b0;
  padding: .18rem 0rem;
}

body .curtomer-style .ant-table .ant-table-tbody tr>td>a {
  color: #b5b1b0;
}

body .curtomer-style .ant-table .ant-table-tbody tr:hover td {
  background: #2f363a;
  cursor: pointer;
}

body .curtomer-style .ant-table .ant-table-tbody tr:hover td a {
  color: #e2b11c;
}

body .curtomer-style .ant-card {
  background-color: rgba(0,0,0,0);
}

body .curtomer-style .ant-card .ant-card-body {
  padding: .21rem 0;
}

body .curtomer-style .ant-card .ant-card-grid {
  border-radius: 0.13rem;
  margin: 0 2% 2% 0;
  cursor: pointer;
}

body .curtomer-style .ant-card .ant-card-grid:nth-child(3n) {
  margin-right: 0%;
}

body .curtomer-style .ant-card .ant-card-grid:hover {
  border: 0.01rem solid #fff;
}

body .curtomer-style .ant-card .title {
  color: #fff;
}

body .curtomer-style .ant-checkbox-wrapper {
  color: #ccc;
}

body .curtomer-style .ant-radio-wrapper {
  color: #fff;
}

body .modalStyle {
  color: #ddd;
}

body .modalStyle .ant-modal-header {
  background-color: #323b40;
  border-bottom: none;
}

body .modalStyle .ant-modal-title {
  color: #ddd;
}

body .modalStyle .ant-modal-close-x {
  color: #ddd;
}

body .modalStyle .ant-modal-body {
  background-color: #323b40;
}

body .modalStyle .ant-modal-footer {
  background-color: #323b40;
  border-top: none;
}

body .modalStyle .ant-input {
  background-color: rgba(0,0,0,0);
  color: #ddd;
}

body .modalStyle .ant-select-selector {
  background-color: rgba(0,0,0,0) !important;
  font-size: .15rem;
  color: #fff;
  height: .38rem;
}

body .modalStyle .ant-tree-list-holder-inner {
  background-color: #323b40;
  color: #ddd;
}

body .modalStyle .ant-input-number-input {
  background-color: #323b40;
  color: #fff;
  border-color: #b5b1b0;
  font-size: .15rem;
}

body .modalStyle .ant-form-item-label label {
  color: #ddd;
}

body .modalStyle .ant-input-textarea-show-count::after {
  color: #fff;
}

body .flex {
  display: -moz-flex;
  display: flex;
}

body .login-container input:-webkit-autofill,
body .login-container input:-webkit-autofill:hover,
body .login-container input:-webkit-autofill:focus,
body .login-container input:-webkit-autofill:active {
  -webkit-transition-delay: 111111s;
  -webkit-transition: color 11111s ease-out,background-color 111111s ease-out;
}

body .customer-flex {
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
}

body .customer-card {
  border-radius: .2rem;
  overflow: hidden;
}

body .customer-card.ant-card-bordered {
  border: none;
}

body .customer-card .ant-card-head {
  background: #323b40;
  border-color: #323b40;
  border-width: 0.03rem;
}

body .customer-card .ant-card-body {
  background: #323b40;
}

body .customer-card.ant-card {
  background: rgba(0,0,0,0);
}

body .customer-collapse {
  background-color: #323b40;
  border-color: #323b40;
}

body .customer-collapse .ant-collapse-content-box {
  background-color: #323b40;
}

body .customer-collapse.ant-collapse>.ant-collapse-item {
  border-color: #323b40;
}

body .customer-collapse.ant-collapse>.ant-collapse-item .ant-collapse-header {
  color: #b5b1b1;
}

body .customer-collapse .ant-btn>span+.anticon {
  margin-left: 0;
}

body .customer-collapse .ant-collapse-content {
  background-color: #323b40;
  border-color: #323b40;
}

body .customer-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  background: #fff;
  color: #000;
  font-weight: bolder;
}

body .customer-form-white .ant-form-item-label>label {
  color: #ccc;
  font-size: .15rem;
}

body .customer-white-color .ant-badge,
body .customer-white-color .ant-checkbox-wrapper,
body .customer-white-color strong,
body .customer-white-color .unit {
  color: #fff;
}

body .customer-white-color .ant-radio-wrapper {
  color: #fff;
}

body .highcharts-exporting-group rect {
  fill: rgba(0,0,0,0);
}

body .highcharts-exporting-group path {
  stroke: #8a8a8a;
}

body .has-date-picker-arrow .ant-picker:hover,
body .has-date-picker-arrow .ant-picker-focused {
  border-color: rgba(0,0,0,0);
  border-right-width: 0 !important;
}

body .has-date-picker-arrow .ant-picker-focused {
  box-shadow: 0 0 0 0rem;
}

body .has-date-picker-arrow .ant-picker {
  border-radius: 0rem;
}

body .has-date-picker-arrow .pre-icon,
body .has-date-picker-arrow .next-icon {
  border-top: .01rem solid #b5b1b0;
  border-bottom: .01rem solid #b5b1b0;
  padding: 0 0.05rem;
}

body .has-date-picker-arrow .pre-icon {
  border-left: .01rem solid #b5b1b0;
}

body .has-date-picker-arrow .next-icon {
  border-right: .01rem solid #b5b1b0;
}

body .has-date-picker-arrow .ant-date-picker-customer.ant-picker.ant-picker-small {
  border-left: 0 solid #ccc;
  border-right: 0 solid #ccc;
}

body .ant-picker-date-panel,
body .ant-picker-month-panel,
body .ant-picker-year-panel,
body .ant-picker-decade-panel,
body .ant-picker-datetime-panel {
  border-color: #cecece;
}

body .ant-picker-date-panel .ant-picker-time-panel,
body .ant-picker-month-panel .ant-picker-time-panel,
body .ant-picker-year-panel .ant-picker-time-panel,
body .ant-picker-decade-panel .ant-picker-time-panel,
body .ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: .01rem solid #999;
}

body .ant-picker-date-panel .ant-picker-time-panel-column:not(:first-child),
body .ant-picker-month-panel .ant-picker-time-panel-column:not(:first-child),
body .ant-picker-year-panel .ant-picker-time-panel-column:not(:first-child),
body .ant-picker-decade-panel .ant-picker-time-panel-column:not(:first-child),
body .ant-picker-datetime-panel .ant-picker-time-panel-column:not(:first-child) {
  border-left: .01rem solid #999;
}

body .ant-picker-date-panel .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
body .ant-picker-month-panel .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
body .ant-picker-year-panel .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
body .ant-picker-decade-panel .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
body .ant-picker-datetime-panel .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: #ccc;
}

body .ant-picker-panel {
  border-color: rgba(0,0,0,0);
}

body .ant-picker-panel .ant-picker-footer {
  background: #fff;
  border-top: .01rem solid #999;
}

body .ant-picker-header,
body .ant-picker-body {
  color: #fff;
}

body .ant-picker-header button,
body .ant-picker-body button {
  color: #fff;
}

body .ant-picker-header button:hover,
body .ant-picker-body button:hover {
  color: #ffd429;
}

body .ant-picker-header .ant-picker-cell .ant-picker-cell-inner:hover,
body .ant-picker-body .ant-picker-cell .ant-picker-cell-inner:hover {
  background-color: rgba(249,190,0,.3) !important;
}

body .ant-picker-body {
  border-color: rgba(0,0,0,0);
}

body .ant-picker-header {
  border-color: #cecece;
}

body .el-date-table td.disabled div {
  background-color: #393b50;
}

body .ant-picker-header button {
  color: #989494;
}

body .ant-picker-header .ant-picker-header-view button {
  color: rgba(51,51,51,.8);
}

body .ant-picker-content td {
  color: #989494;
}

body .ant-picker-content td.ant-picker-cell-in-view {
  color: rgba(51,51,51,.8);
  text-align: center;
}

body .ant-picker-content th {
  color: rgba(51,51,51,.8);
}

body td:hover .ant-picker-cell-inner {
  background-color: rgba(249,190,0,.3) !important;
}

body .el-picker-panel__icon-btn {
  color: #323b40;
}

body #parent-baidu-map {
  position: absolute;
  min-height: calc(100vh + 0.2rem);
  width: 100%;
}

body #parent-baidu-map #baidumap-container #platform #mask {
  display: none !important;
}

body #parent-baidu-map .screen-button {
  position: absolute;
  right: 0.16rem;
  top: 0.05rem;
  padding: 0.13rem;
  background: #fff;
  z-index: 10;
  border-color: #8ba4dc;
  box-shadow: rgba(0,0,0,.35) 0.01rem 0.01rem 0.05rem;
  border-width: .01rem;
  border-style: solid;
}

body #parent-baidu-map .screen-button:hover {
  cursor: pointer;
}

body #parent-baidu-map .screen-button:hover img.gray {
  display: none !important;
}

body #parent-baidu-map .screen-button:hover img.dark {
  display: block !important;
}

@media screen and (min-width: 1400px) {
  body .map_container .Gmaps {
    min-height: calc(100vh + 1.33rem);
  }
}

@media screen and (max-width: 1400px) {
  body .map_container .Gmaps {
    min-height: calc(100vh + 2.53rem);
  }
}

@media screen and (max-width: 992px) {
  body .home_container .carousel-col-content .ant-row .ant-col>div {
    border-width: 0;
  }

  body .running-state {
    height: 4.27rem;
  }
}

@media screen and (max-width: 1200px) {
  body .home_container .carousel-col-content {
    padding-right: 0.6rem !important;
  }
}

body .isFullScreen #parent-baidu-map {
  min-height: calc(100vh - 0.67rem);
}

body .isFullScreen .map_container .Gmaps {
  min-height: calc(100vh - 0.67rem);
}

body .has-date-picker-arrow .pre-icon:hover,
body .has-date-picker-arrow .next-icon:hover {
  background: rgba(0,0,0,0);
  cursor: pointer;
}

body .has-date-picker-arrow .hover-hand:hover {
  background: rgba(0,0,0,0);
}

body .customer-high-charts .highcharts-menu {
  box-shadow: rgba(0,0,0,0) 0.04rem 0.04rem 0.13rem !important;
  border: 0.01rem solid #b5b1b0 !important;
  background: #323b40 !important;
}

body .customer-high-charts .highcharts-menu li {
  color: #e8e8e8 !important;
}

body .customer-select-style.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(0,0,0,0) !important;
  font-size: .15rem;
  color: #fff;
  height: .38rem;
}

body .ant-form-item-label>label {
  font-weight: 500;
}

body .ant-table-thead>tr>th {
  font-weight: 600;
}

body .ant-btn {
  border-radius: 0.07rem;
}

body .ant-btn.ant-btn-primary {
  color: #333;
}

body .ant-input,
body .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0.07rem;
  color: #4f4f4f;
  color: var(--theme-color);
}

body .ant-input-number {
  background-color: #f4f4f4 !important;
  color: #4f4f4f;
  color: var(--theme-color);
  width: 100%;
}

body .ant-picker-input>input {
  color: #4f4f4f;
  color: var(--theme-color);
}

body .ant-card {
  border-radius: 0.13rem;
}

body .ant-select-item {
  color: #989494;
}

body .ant-tree-show-line .ant-tree-switcher.ant-tree-switcher_close {
  background: url("/static/svg/close-tree.svg") no-repeat;
  background-size: 90% 45%;
  background-position: 0 0.08rem;
}

body .ant-tree-show-line .ant-tree-switcher.ant-tree-switcher_open {
  background: url("/static/svg/open-tree.svg") no-repeat;
  background-size: 90% 26%;
  background-position: 0 0.11rem;
}

body .ant-tree-show-line .ant-tree-switcher-line-icon {
  opacity: 0;
}

body .ant-breadcrumb {
  font-weight: 600;
}

body .ant-breadcrumb li {
  color: #4f4f4f;
  font-size: 0.21rem;
}

body .ant-select-arrow,
body .ant-picker-suffix,
body .ant-input-affix-wrapper,
body .ant-upload-list-item-info .ant-upload-text-icon .anticon,
body .ant-picker.ant-picker-disabled .ant-picker-suffix,
body .ant-upload-list-item-card-actions .anticon {
  color: rgba(0,0,0,.25);
}

body .pre-icon,
body .next-icon {
  color: #4f4f4f;
}

body .ant-form {
  color: #4f4f4f;
  color: var(--theme-color);
}

body .ant-breadcrumb-separator {
  color: rgba(51, 51, 51, 0.8);
  color: var(--bread-text-color);
}

body .ant-breadcrumb li:last-child {
  color: rgba(51, 51, 51, 0.9);
  color: var(--bread-high-light-color);
}

body .ant-collapse-header,
body .ant-collapse-content {
  border-color: rgba(0,0,0,0);
}

body .ant-collapse {
  border: 0.01rem solid rgba(0,0,0,0);
}

body .ant-collapse .ant-collapse-item {
  border-color: rgba(0,0,0,0);
}

body .ant-collapse .ant-collapse-item .ant-collapse-header {
  color: #4f4f4f;
  color: var(--theme-color);
}

::-webkit-scrollbar {
  width: 0.05rem;
  height: 0.05rem;
  background-color: #989494;
}

::-webkit-scrollbar-track {
  background-color: #989494;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: #989494;
  border-radius: 2em;
}

.textColor {
  color: #4f4f4f !important;
}

.confirmColor .ant-modal-content .ant-modal-body .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .ant-modal-confirm-title {
  color: #4f4f4f !important;
}

.main-system-interface-container .data-preview .ant-col {
  position: relative;
  padding-left: 0.87rem;
}

.main-system-interface-container .data-preview .ant-col img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.main-system-interface-container .data-preview p:nth-child(2) {
  color: #4f4f4f;
  font-weight: 500;
  margin-bottom: 0;
}

.main-system-interface-container .data-preview p:last-child {
  color: #4f4f4f;
  font-weight: 600;
  font-size: 0.27rem;
  margin-bottom: 0;
}

@media screen and (max-width: 1200px) {
  .main-system-interface-container .data-preview .ant-col {
    margin-bottom: 0.27rem;
    padding-left: 0.93rem;
  }
}

@media screen and (max-width: 1400px) {
  .main-system-interface-container .data-preview p:nth-child(2) {
    font-size: 0.16rem;
  }

  .main-system-interface-container .data-preview p:last-child {
    font-size: 0.19rem;
  }
}

@media screen and (max-width: 768px) {
  .main-system-interface-container .data-preview .ant-col {
    margin-bottom: 0.4rem;
    padding-left: 1.33rem;
  }
}

body #root .ant-divider {
  border-top: 0.01rem solid #cecece;
}

body .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #cecece;
}

body a.ant-btn-disabled {
  color: #4f4f4f;
  color: var(--theme-color);
}

body .home_container .carousel-col-content .ant-row {
  background: hsla(0,0%,100%,.75);
}

body .home_container .carousel-col-content .ant-row .ant-col .home-img-1 {
  content: url(/static/svg/Capacity_Installed.svg);
  content: var(--home-img-one);
}

body .home_container .carousel-col-content .ant-row .ant-col .home-img-2 {
  content: url(/static/svg/Power_Installed.svg);
  content: var(--home-img-two);
}

body .home_container .carousel-col-content .ant-row .ant-col .home-img-3 {
  content: url(/static/svg/PV_Generation.svg);
  content: var(--home-img-three);
}

body .home_container .carousel-col-content .ant-row .ant-col .home-img-4 {
  content: url(/static/svg/Today_Charge.svg);
  content: var(--home-img-four);
}

body .home_container .carousel-col-content .ant-row .ant-col .home-img-5 {
  content: url(/static/svg/TOTAL_DISCHARGED.svg);
  content: var(--home-img-five);
}

body .ant-table {
  color: #4f4f4f;
}

body .primary-color {
  color: #f9be00;
}

body .ant-card-head {
  border-color: #cecece;
}

body .single-battery-content .content-without-customer-installer {
  border: 0.01rem solid #cecece !important;
  padding: 0.13rem;
  border-radius: 0.13rem;
}

body .single-battery-content .bms-battery-list.column-row {
  border: 0 solid rgba(0,0,0,0) !important;
}

body .single-battery-content .bms-battery-list.column-row section {
  border: 0.01rem solid #cecece !important;
  padding: 0.13rem 0.13rem 0.07rem 0.13rem;
  border-radius: 0.13rem;
}

body .single-battery-content .single-battery-title {
  color: #4f4f4f;
  font-size: 0.19rem;
  font-weight: 400;
}

body .single-battery-content .divide-line {
  height: 0.04rem;
  background: #f9be00;
  margin-top: 0.13rem;
}

body .single-battery-content .column-row {
  display: flex;
  width: 100%;
  border: 0.01rem solid #cecece !important;
  padding: 0.11rem;
}

body .single-battery-content .column-row .column-row-left {
  flex: 1 1;
  overflow: auto;
}

body .single-battery-content .column-row .ant-list-item {
  margin-bottom: 0;
}

body .single-battery-content .column-row table {
  width: 2.67rem;
  flex-shrink: 0;
}

body .single-battery-content .column-row table tr {
  line-height: 3;
}

body .single-battery-content .column-row table td {
  color: #4f4f4f;
}

body .single-battery-content .chuan-battery {
  text-align: center;
  background: #a3a3a3;
  padding-top: 0.13rem;
  padding-bottom: 0.11rem;
  margin-bottom: 0;
  cursor: pointer;
  color: #fff;
  border-radius: 0.07rem;
}

body .single-battery-content .chuan-battery.currentIndex {
  background: #838383;
}

body .single-battery-content .chuan-battery.warning {
  background: #ff4a55;
}

body .single-battery-content .chuan-battery li:first-child {
  margin: 0.03rem 0;
}

body .single-battery-content .chuan-battery li:first-child span {
  height: 0.04rem;
  background: hsla(0,0%,100%,.8);
  display: block;
  width: 60%;
  margin: auto;
  border-radius: 0.03rem;
}

body .single-battery-content .chuan-battery li:last-child {
  margin-top: 0.05rem;
}

body .single-battery-content .chuan-battery li:last-child span {
  display: block;
  margin: auto;
  padding: 0 0.05rem;
  background: hsla(0,0%,100%,.8);
  width: 90%;
  border-radius: 0.07rem;
  font-size: 0.16rem;
  color: #4f4f4f;
  color: var(--theme-color);
}

body .ant-card.ant-card-bordered {
  border-color: #fff;
}

body .ant-dropdown .ant-dropdown-menu-item {
  color: #4f4f4f;
}

body .ant-dropdown .ant-dropdown-menu-item:hover {
  background: #f7f8fa;
}

body .ant-dropdown .ant-table-filter-dropdown-btns .ant-btn-link {
  color: #4f4f4f;
}

body table td {
  color: #4f4f4f;
}

body .ant-modal-body table td {
  padding: 0.13rem;
  border-bottom: 0.01rem solid #cecece;
}

body .ant-radio-wrapper {
  color: #4f4f4f;
  color: var(--theme-color);
}

body button.ant-switch-checked .ant-switch-handle:before {
  background-color: #fff;
}

body button.ant-switch-checked:focus {
  box-shadow: 0 0 0;
}

body .ant-table-thead .ant-table-cell {
  border-color: #f7f8fa;
}

body .ant-radio-inner {
  border-color: #cecece;
}

body .ant-radio-disabled .ant-radio-inner {
  background-color: #f4f4f4;
  border-color: #cecece;
}

body .ant-radio-disabled+span {
  color: #4f4f4f;
}

body .ant-upload .ant-btn.ant-btn-default {
  border-color: rgba(0,0,0,0);
  background: #ffa423;
  background: linear-gradient(155.78deg, #FDD568 10.04%, #FFA423 69.34%);
}

body .ant-upload-list {
  color: #4f4f4f;
}

body .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f7f8fa;
}

body .ant-message {
  color: #4f4f4f;
}

body .menu-tree-auth .ant-tree-title {
  color: #4f4f4f;
  color: var(--theme-color);
}

.span-them-color {
  color: #4f4f4f;
  color: var(--theme-color);
}

.socCaLibra-card {
  margin-bottom: 0.13rem !important;
  border-color: #4f4f4f !important;
  border-color: var(--theme-color) !important;
}

.socCaLibra-card .ant-card-body {
  padding-bottom: 0;
}

.ant-table-filter-dropdown-search-input .anticon {
  color: #4f4f4f !important;
}