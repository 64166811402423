.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 1.07rem;
}

.App-header {
  background-color: #222;
  height: 2rem;
  padding: 0.27rem;
  color: #fff;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-layout {
  height: 100%;
}

.trigger {
  font-size: 0.24rem;
  line-height: 0.85rem;
  padding: 0 0.32rem;
  cursor: pointer;
  transition: color .3s;
}

.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
  border: none !important;
  background: #fff !important;
  margin-right: 0.27rem !important;
}

.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab i {
  width: 0 !important;
  transition: width .4s !important;
}

.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab:hover i {
  width: 0.21rem !important;
  transition: width .4s !important;
}

.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active {
  border-bottom: 0.01rem solid #1890ff !important;
}

.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab-active i {
  display: inline-block !important;
}

.ant-menu-inline .ant-menu-item::after {
  right: 100% !important;
  left: .0.07rem !important;
}

.ant-menu-horizontal>.ant-menu-item-selected {
  border-bottom: none !important;
  border-top: 0.03rem solid #1890ff !important;
}

.ant-advanced-search-form {
  padding: 0.32rem;
  background: #fafafa;
  border-radius: 0.08rem;
}

.responsive-drawer .ant-drawer-body {
  padding: 0;
}

.responsive-drawer .ant-drawer-body .ant-layout-sider {
  height: 100%;
  border-bottom-right-radius: 0.2rem;
}

.responsive-drawer .ant-drawer-body .ant-layout-sider .ant-layout-sider-children>.ant-menu {
  height: 100%;
}