:root {
  --configuration-color: #ffffff;
  --border-color: #cecece;
  --header-color: #fff;
  --chart-bg-color: #ffffff;
  --chart-grid-line-color: #cecece;
  --chart-line-color: #cecece;
  --chart-legend-color: #4f4f4f;
  --chart-axias-text-color: #8a8a8a;
  --chart-title-color: #4f4f4f;
  --collapsed-icon-color: #1b1f3b;
  --main-bg-color: #f4f4f4;
  --header-bg-color: #ffffff;
  --theme-color: #4f4f4f;
  --primary-color: #f9be00;
  --secretary-color: #8a8a8a;
  --second-chart-bg-color: #fbfbfb;
  --home-img-one: url(/static/svg/Capacity_Installed.svg);
  --battery-img: url(/static/svg/air-conditioner-light.svg);
  --home-img-two: url(/static/svg/Power_Installed.svg);
  --home-img-three: url(/static/svg/PV_Generation.svg);
  --home-img-four: url(/static/svg/Today_Charge.svg);
  --home-img-five: url(/static/svg/TOTAL_DISCHARGED.svg);
  --text-opacity-color: rgba(51, 51, 51, 0.8);
  --bg-opacity-color: rgba(255, 255, 255, 0.75);
  --text-opacity-color-80: rgba(255, 255, 255, 0.8);
  --map-land: #f5f5f3;
  --map-water: #ccd2d3ff;
  --home-text-color: rgba(51, 51, 51, 0.8);
  --home-chart-bg-color: rgba(251, 251, 251, 0.8);
  --home-chart-circle-bg-color: #e2e2e2;
  --home-chart-circle-title-text-color: rgba(0, 0, 0, 0.45);
  --home-chart-circle-title-text-color-total: rgba(0, 0, 0, 0.8);
  --home-more-bg-color: rgba(251, 251, 251, 0.8);
  --home-more-titlt-color: #4f4f4f;
  --home-more-btn-text-color: #4f4f4f;
  --home-more-btn-bg-color: #ffffff;
  --home-more-btn-border-color: #989494;
  --home-data-text-color: rgba(0, 0, 0, 0.8);
  --home-baidu-map-bg-color: #ffffff;
  --home-baidu-map-text-color: rgba(51, 51, 51, 0.8);
  --home-profit-text-color: #8a8a8a;
  --home-data-text-color-two: #8a8a8a;
  --baidu-map-w3: url(/static/images/w3.png);
  --tooltip-bg-color: #3e3f43;
  --tooltip-color-text-one: rgba(51, 51, 51, 0.8);
  --exporting-bg-color: #ffffff;
  --cluster-data-header-bg-color: #f7f8fa;
  --chart-axis-text-color: #989494;
  --bread-high-light-color: rgba(51, 51, 51, 0.9);
  --bread-text-color: rgba(51, 51, 51, 0.8);
  --electrical-flow-chart-flow: "/resource/mxgraph/shapes/12/11.png";
  --electrical-flow-chart-tank: "/resource/mxgraph/shapes/12/10.png";
  --electrical-flow-chart-pv: "/resource/mxgraph/shapes/12/9.png";
  --electrical-flow-chart-otherLoad: "/resource/mxgraph/shapes/12/7.png";
  --electrical-flow-chart-importantLoad: "/resource/mxgraph/shapes/12/6.png";
  --electrical-flow-chart-fuse: "/resource/mxgraph/shapes/12/5.png";
  --electrical-flow-chart-electricityMeter: "/resource/mxgraph/shapes/12/4.png";
  --electrical-flow-chart-container: "/resource/mxgraph/shapes/12/3.png";
  --electrical-flow-chart-powerGrid: "/resource/mxgraph/shapes/12/2.png";
  --electrical-flow-chart-chargingPile: "/resource/mxgraph/shapes/12/1.png";
  --electrical-flow-chart-battery: "/resource/mxgraph/shapes/12/0.png";
  --mxgraph-bg-img: url(/resource/mxgraph/images/mxgraph-content-light.svg);
  --mxgraph-select-border-color: #cccc;
  --mxgraph-input-bg-color: #ffffff;
  --mxgraph-option-bg-color: #ffffff;
  --mxgraph-option-text-color: #ffffff;
  --mxgraph-option-hover-color: #ffffff;
  --mxgraph-select-text-color: #ffffff;
  --mxgraph-split-hover-color: #e0e0e0;
  --mxgraph-dialog-bg-color: rgba(0, 0, 0, 0.15);
  --select-border-color: #cecece;
  --mxgraph-grid-color: #d0d0d0;
  --card-grid-bg: #cecece;
  --fontSize_45: 0.6rem;
}

@titlebg :rgb (56, 98, 148);:export {
  menuText: #b5b1b1;
  menuActiveText: #fff;
  subMenuActiveText: #fff;
  menuBg: #464d55;
  menuHover: #2f3c44;
  subMenuBg: #464d55;
  subMenuHover: #2f3c44;
  sideBarWidth: 4rem;
  containerBg: #323b40;
  borderColor: #323b40;
  breadcrumbBg: #0c4555;
  lighBlue: #03e4eb;
  deepYellow: #f8b90a;
  runStatusNormal: #87ca16;
  runStatusWarning: #ff9500;
  runStatusFault: #ff4a55;
  runStatusOffline: #999;
  menuActiveBg: #fff;
  cardMarginTop: 0.21rem;
}

.login-container {
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-image: url("/static/images/loginBg.png");
  overflow: hidden;
}

.login-container .logo .more-info {
  position: absolute;
  right: 4rem;
  top: 0.4rem;
  margin: 0 0.07rem;
  padding: 0;
  color: hsla(0,0%,100%,.8) !important;
  font-size: 0.19rem;
  list-style: none;
  display: flex;
  align-items: center;
}

.login-container .logo .more-info li a {
  color: hsla(0,0%,100%,.8) !important;
}

.login-container .logo .more-info .separate {
  padding: 0 0.08rem;
}

.login-container .anticon.ant-input-password-icon {
  color: #ccc;
}

.login-container .mb20 {
  margin-bottom: 0.27rem;
}

.login-container .overflow_hidden {
  overflow: hidden;
}

.login-container .login-form {
  min-width: 3.8rem;
}

.login-container .login-form .ant-form-item {
  margin-bottom: 0.16rem;
}

.login-container .login-form .ant-form-item-explain {
  text-align: left;
}

.login-container .login-form-button {
  width: 100%;
  background-color: #323b40;
  font-size: 0.21rem;
  height: 0.48rem;
  border-radius: 0.33rem;
}

.login-container .login-form-forgot {
  color: #000;
}

.login-container .login-form-register {
  width: 100%;
  display: block;
  border: 0.01rem solid #b5b1b1;
  background-color: #fff;
  font-size: 0.21rem;
  height: 0.53rem;
  line-height: 0.53rem;
  border-radius: 0.33rem;
  color: #333;
}

.login-container .logo-content {
  position: absolute;
  left: 10%;
  top: 0.33rem;
  width: 1.6rem;
}

.login-container .layout-left {
  float: left;
  margin-top: 2.2rem;
  margin-left: 1.87rem;
}

.login-container .layout-left .layout-box {
  padding: 0.27rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 53.18%);
  border-radius: 0.2rem;
}

.login-container .layout-left .layout-box .p1 {
  color: #fff;
  font-size: .3rem;
  margin: 0.07rem 0;
  font-weight: 600;
}

.login-container .layout-left .layout-box .p2 {
  color: #fff;
  font-size: .4rem;
  margin: 0.07rem 0;
  font-weight: 600;
  padding: 0.2rem 0;
}

.login-container .layout-left .layout-box .p3 {
  color: #fff;
  font-size: .5rem;
  margin: 0.07rem 0 20x 0;
}

.login-container .layout-left .layout-box .p3 strong {
  font-weight: 600;
  color: #eeb918;
}

.login-container .layout-left .layout-box .login-icon {
  margin-right: 0.27rem;
}

.login-container .layout-left .layout-box .login-icon+span {
  margin-left: 0.27rem;
}

.login-container .layout-left .layout-box .login-icon+.login-bar-animation {
  margin-left: 1rem;
}

.login-container .layout-left .layout-box .login-bar {
  width: 3.47rem;
  display: block;
}

.login-container .layout-left .layout-box .login-bar-animation {
  border-radius: 0.07rem;
  height: 0.05rem;
  display: block;
  margin-left: 0.8rem;
  margin-top: -0.13rem;
  width: 100%;
}

.login-container .layout-left .layout-box .yellow {
  animation: shanke 20s ease-in forwards;
  background-color: #fec171;
}

.login-container .layout-left .layout-box .blue {
  background-color: #5cb85c;
}

.login-container .layout-left .layout-box .white {
  animation: chz_shanke 27s ease-in forwards;
  background-color: #fff;
}

.login-container .layout-right {
  height: 100%;
  margin-right: 2.4rem;
  float: right;
}

.login-container .layout-right .login-bg {
  padding: 0.4rem;
  background-color: #272e32;
  border: .0.07rem solid #ccc;
  border-radius: 0.21rem;
  background-image: linear-gradient(to bottom, #1a1f24, #4a4e50);
  font-size: 0.19rem;
  text-align: center;
}

.login-container .layout-right .login-bg>ul>li {
  font-size: 0.53rem;
}

.login-container .layout-right .ant-input,
.login-container .layout-right .ant-input-affix-wrapper {
  border-radius: 0.07rem;
}

.login-container .switch-language {
  position: absolute;
  right: 2.93rem;
  top: 0.33rem;
  width: 1.2rem;
  border-radius: 0.4rem;
}

.login-container .switch-language .dropdown-toggle {
  position: absolute;
  right: 0;
  top: 0;
  width: 1.33rem;
  text-align: center;
}

.login-container #noise-canvas {
  position: absolute;
  left: 45%;
  width: 1536;
  height: 470;
}

.login-container .ant-row-flex {
  height: 100%;
}

.login-container .ant-card-body {
  padding: 0;
}

.login-container .ant-card {
  background: rgba(16,27,43,.6);
}

.login-container .ant-input-affix-wrapper {
  background-color: #fff;
  color: #e6e6fa;
  border-color: #eeb918;
}

.login-container .ant-input-affix-wrapper:hover {
  border-color: #eeb918;
}

.login-container .login-bottom {
  color: #fff;
  font-size: 0.19rem;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 3%;
}

@keyframes shanke {
  0% {
    width: 0;
    background: #fabf00;
  }

  25% {
    width: 25%;
    background: #fabf00;
  }

  50% {
    width: 50%;
    background: #fabf00;
  }

  75% {
    width: 75%;
    background: #fabf00;
  }

  100% {
    width: 100%;
    background: #fabf00;
  }
}

@keyframes chz_shanke {
  0% {
    width: 0;
    background: #fff;
  }

  25% {
    width: 25%;
    background: #fff;
  }

  50% {
    width: 50%;
    background: #fff;
  }

  75% {
    width: 75%;
    background: #fff;
  }

  100% {
    width: 100%;
    background: #fff;
  }
}

.register_mainBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register_mainBox .forget_main,
.register_mainBox .register_main {
  width: 100%;
}

.register_mainBox .forget_main .forget_form_body,
.register_mainBox .register_main .forget_form_body {
  width: 30%;
  margin: auto;
}

.register_mainBox .forget_main .register_body,
.register_mainBox .register_main .register_body {
  width: 50%;
  margin: auto;
}

.register_mainBox .forget_main .logo,
.register_mainBox .register_main .logo {
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.register_mainBox .register_login {
  text-align: right;
  color: #fff;
}

.register_mainBox .register_login .register_text_Account {
  font-size: 0.19rem;
}

.register_mainBox .register_login .register_text_login {
  font-weight: bold;
  font-size: 0.24rem;
}

.register_mainBox .register_form {
  width: 70%;
  margin: 0 auto;
}

.register_mainBox .forget_form {
  width: 50%;
  margin: 0 auto;
}

.register_mainBox .forget_hundred {
  width: 100%;
}

.register_mainBox .register_bg {
  background-color: #fff;
  margin: .2rem auto;
  padding-bottom: 0.53rem;
  border-radius: 0.13rem;
}

.register_mainBox .register_bg img {
  max-width: 90%;
}

.register_mainBox .register_title {
  text-align: center;
  height: 0.53rem;
  line-height: 0.53rem;
  border-top-left-radius: 0.07rem;
  border-top-right-radius: 0.07rem;
  background-color: #f8b90a;
  font-size: .25rem;
  color: #333;
}

.register_mainBox .register_box {
  margin: 0 auto;
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
}

.register_mainBox .register_btn {
  width: 50%;
  margin-left: 25%;
  height: 0.53rem;
  font-size: .25rem;
  border-radius: 0.67rem;
  margin-top: .5rem;
}

.register_mainBox .forget_btn {
  width: 100%;
  height: 0.53rem;
  font-size: .25rem;
  border-radius: 0.67rem;
}

.register_mainBox #normal_login .ant-form-item-label label {
  color: #000;
}