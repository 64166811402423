.cookie-tips {
  position: absolute;
  bottom: 0.67rem;
  height: 0.87rem;
  width: 100%;
  background-color: rgba(131,134,136,.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.21rem;
}

.cookie-tips-close {
  width: 0.67rem;
  font-size: 0.32rem;
  color: #fabf00;
}

.cookie-tips-hide {
  display: none;
}

.exclamationCircle {
  color: #fff;
  width: 0.27rem;
  display: inline-block;
}